<template>
  <Teleport to="body">
    <div class="gift-page" :class="{ 'show-ani': showBox, 'cyber-monday': cyberMonday }">
      <div class="gift-box">
        <Close class="close" @click="showBox = false"></Close>
        <div class="dis">
          <span> {{ useTranslateI18n(`payment.blackFridays.hot`) }} </span>
          <CommonImage :src="cyberMonday ? CircleMonday : Circle" background="transparent" />
        </div>
        <div class="title" v-html="blackFridayDesc"></div>
        <CommonButton ref="btnRef" :button-style="buttonStyle" @btn-click="toPrice">
          <CommonAnimationScrollText>{{ useTranslateI18n(`payment.topCard[1].btn[0]`) }}</CommonAnimationScrollText>
        </CommonButton>

        <Star class="star s-1"></Star>
        <Star class="star s-2"></Star>
        <Star class="star s-3"></Star>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import Close from "@/assets/icons/pages/home/close.svg"
import Star from "@/assets/icons/pages/home/star.svg"
import { PAYMENT_PAGE_PATH } from "~/constant/route"
import { useAllActivitys, IActivityType } from "~/constant/useAllActivitys"

const Circle = getCloudAssets("/images/pages/blackFridays/circle.png")
const CircleMonday = getCloudAssets("/images/pages/blackFridays/circle_monday.png")

const gift1 = `url(${getCloudAssets("/images/pages/blackFridays/gift_bg_monday.png")})`
const gift2 = `url(${getCloudAssets("/images/pages/blackFridays/gift_bg.png")})`

const { hallows_open_type } = useAllActivitys()

const cyberMonday = ref(hallows_open_type.value === IActivityType.CyberFriday)

const blackFridayDesc = computed(() => {
  return !cyberMonday.value
    ? useTranslateI18n("payment.blackFridays.t7", [
        { type: "static", class: "light" },
        useIsNewSubPage().value ? "35%" : "30%",
        { type: "static", class: "title-d", tag: "div" },
        {
          type: "static",
          class: "title-d",
          tag: "div"
        }
      ])
    : useTranslateI18n("payment.blackFridays.t8", [
        { type: "static", class: "light" },
        useIsNewSubPage().value ? "35%" : "30%",
        { type: "static", class: "title-d", tag: "div" },
        {
          type: "static",
          class: "title-d",
          tag: "div"
        }
      ])
})

const buttonStyle = reactive({
  background: "#ffe050",
  color: `#000`,
  borderRadius: "60px",
  height: "42px"
})

const showBox = ref(false)

let startScroll = false

let windowScrollEvent = null

const { start, stop } = useTimeoutFn(() => {
  showBox.value = true
}, 5000)

function initScrollStyle() {
  windowScrollEvent = useEventListener(window, "scroll", function () {
    if (!startScroll) {
      startScroll = true
      start()
    }
  })
}

const toPrice = () => {
  navigateTo(unref(PAYMENT_PAGE_PATH))
}

onMounted(() => {
  stop()
  initScrollStyle()
})

onBeforeUnmount(() => {
  windowScrollEvent && windowScrollEvent()
})
</script>

<style lang="scss" scoped>
.gift-page {
  display: inline-block;
  right: 120px;
  position: fixed;
  bottom: 60px;
  z-index: 11;
  opacity: 0;
  width: 0;
  height: 0;

  .gift-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 329px;
    padding: 28px 16px;
    border-radius: 16px;

    background: v-bind(gift2) no-repeat 0px 100px,
      #000 linear-gradient(180deg, rgba(130, 179, 253, 0.15) 0%, rgba(0, 0, 0, 0.19) 100%);
    background-size: 100%;

    .close {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .dis {
      position: absolute;
      left: 10px;
      bottom: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      aspect-ratio: 1/1;

      span {
        color: #000;
        text-align: center;
        font-family: "Roboto-Bold" sans-serif;
        font-size: 17.982px;
        font-style: italic;
        font-weight: 700;
        z-index: 1;
      }

      :deep(.img-load) {
        position: absolute;
        inset: 0;
      }
    }

    .title {
      color: #fff;
      text-align: center;
      padding: 0 27px;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 117.217%;

      :deep(.light) {
        color: #ffe050;
      }
    }

    .star {
      position: absolute;
      width: 30px;
      height: 30px;

      &.s-1 {
        left: 46px;
        top: 96px;
        animation: star 2s infinite alternate;
      }

      &.s-2 {
        left: 26px;
        bottom: 80px;
        animation: star 2s infinite alternate;
        animation-delay: 2s;
      }

      &.s-3 {
        right: 48px;
        top: 120px;
        animation: star 2s infinite alternate;
        animation-delay: 3s;
      }
    }
  }

  &.cyber-monday {
    .gift-box {
      background: v-bind(gift1) no-repeat 0px 100px,
        #000 linear-gradient(180deg, rgba(130, 179, 253, 0.15) 0%, rgba(0, 0, 0, 0.19) 100%);
      background-size: 100%;
    }
  }
  &.show-ani {
    animation: giftBox 1s forwards;
  }
}

@keyframes giftBox {
  0% {
    width: auto;
    height: auto;
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }
  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }
  90% {
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }
  100% {
    width: auto;
    height: auto;
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes star {
  from {
    opacity: 1;
    transform: scale(1.5);
  }

  to {
    opacity: 0.3;
    transform: scale(0.5);
  }
}

@include device-max-width-900 {
  .gift-page {
    padding-right: 0;
    // right: calc(100vw / 2 - 120px);
    bottom: calc(100vh / 2 - 160px);
  }
}

@include device-max-width-600 {
  .gift-page {
    right: calc(100vw / 2 - 150px);
    left: calc(100vw / 2 - 150px);
  }
}

@include device-max-width-375 {
  .gift-page {
    display: flex;
    justify-content: center;
  }
}
</style>
