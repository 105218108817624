<template>
  <teleport to="body">
    <div :class="['sidebar', showPopup ? 'show-popup' : 'hide-popup']" @click="handleSidebar">
      <div class="sidebar-wrap ani_slide-left" @click.stop="() => {}">
        <TransitionGroup name="aside">
          <PagesHomeSidebarItem
            v-for="(item, i) in sidebar"
            v-show="!item.hidden"
            :key="i"
            :class="item.class"
            :icon="item.icon"
            :tip="item.tip"
            :show-tip="item.showTip"
            @click="item?.onClick(item, $event)"
          >
            <div v-if="i && !item.hidden" class="divider"></div>
          </PagesHomeSidebarItem>
        </TransitionGroup>
      </div>
    </div>

    <div class="sidebar-mobile ani_slide-left" @click="handleClick">
      <ChatIcon></ChatIcon>
    </div>
  </teleport>
</template>
<script lang="ts" setup>
import ContactIcon from "@/assets/icons/pages/home/contact_icon.svg"
import CollaborateIcon from "@/assets/icons/pages/home/collaborate_icon.svg"
import SupportIcon from "@/assets/icons/pages/home/support_icon.svg"
import BackIcon from "@/assets/icons/pages/home/back_icon.svg"
import ChatIcon from "@/assets/icons/pages/home/chat_icon.svg"
import { DefineComponent } from "vue"
import { EmitName } from "~~/constant/eventBus"
import { HELP_PAGE_PATH } from "~~/constant/route"
import { FEEDBACK_ID } from "~~/constant/pages/help"
import { HERO_FREE_BTN } from "~~/constant/pages/home"
import { NAV_ID } from "~~/constant/navbar"
import { COLLABORATE } from "@/constant/route"

let { useCommonMp } = useMp()
let { _pointOfficialPageButtonClick } = useCommonMp()
let { _pointSiderBarClick } = useStSdkCommonMp()

type SidebarItem = {
  icon: DefineComponent
  tip: string
  showTip?: boolean
  class?: string
  hidden?: boolean
  onClick: (i: SidebarItem, e: MouseEvent | TouchEvent) => void
}

const { push } = useRouter()
const hiddenBackTop = ref(true)
const onMountedShow = ref(true)

const sidebar = computed<SidebarItem[]>(() => {
  return [
    {
      icon: CollaborateIcon,
      tip: useTranslateI18n("home.aside.collaboration"),
      async onClick() {
        _pointOfficialPageButtonClick({
          path: useMpCommonPath(),
          pageTitle: useMpCommonPageTitle(),
          buttonName: "collabrationFloatWidget",
          featureModule: "websiteButtonClick",
          featureName: "clickWebsiteHomeWidget"
        })
        _pointSiderBarClick({
          element_name: "collaboration",
          element_type: "button",
          element_position: "float_side_bar"
        })
        showPopup.value = false
        navigateTo(unref(COLLABORATE))
      }
    },
    {
      icon: ContactIcon,
      tip: useTranslateI18n("home.aside.contactSales"),
      // showTip: onMountedShow.value,
      onClick() {
        useContactUsDialog()
        _pointOfficialPageButtonClick({
          path: useMpCommonPath(),
          pageTitle: useMpCommonPageTitle(),
          buttonName: "contactSales",
          featureModule: "websiteButtonClick",
          featureName: "clickWebsiteHomeWidget"
        })
        _pointSiderBarClick({
          element_name: "contact_sales",
          element_type: "button",
          element_position: "float_side_bar"
        })
        showPopup.value = false
      }
    },
    {
      icon: SupportIcon,
      tip: useTranslateI18n("home.aside.techSupport"),
      // showTip: onMountedShow.value,
      onClick() {
        _pointOfficialPageButtonClick({
          path: useMpCommonPath(),
          pageTitle: useMpCommonPageTitle(),
          buttonName: "supportButton",
          featureModule: "websiteButtonClick",
          featureName: "clickWebsiteHomeWidget"
        })
        _pointSiderBarClick({
          element_name: "support",
          element_type: "button",
          element_position: "float_side_bar"
        })
        showPopup.value = false
        navigateTo(`${unref(HELP_PAGE_PATH)}#${FEEDBACK_ID}`)
      }
    },
    {
      icon: BackIcon,
      tip: useTranslateI18n("home.aside.top"),
      hidden: hiddenBackTop.value,
      class: "back-top",
      onClick() {
        backToTop()
      }
    }
  ]
})

// back to top
let timer
const SECTION = 20
const { y } = useWindowScroll()
const { height } = useWindowSize()
watch(y, (v) => {
  if (v > height.value) {
    hiddenBackTop.value = false
  } else {
    hiddenBackTop.value = true
  }

  const btn = document.getElementById(HERO_FREE_BTN)
  const nav = document.getElementById(NAV_ID)
  if (onMountedShow.value && v > btn?.offsetTop + btn?.offsetHeight - nav?.offsetHeight) {
    onMountedShow.value = false
  }
})
const { request, cancel } = useRequestAnimationFrame()
function backToTop() {
  cancel(timer)
  timer = request(function fn() {
    const oTop = document.body.scrollTop || document.documentElement.scrollTop
    if (oTop > 0) {
      scrollBy({
        top: -oTop / SECTION
      })
      timer = request(fn)
    } else {
      cancel(timer)
    }
  })
}

const showPopup = ref(false)
function handleClick() {
  showPopup.value = true
}

const handleSidebar = () => {
  showPopup.value = false
}
</script>
<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &-wrap {
    display: inline-flex;
    flex-direction: column;
    width: 52px;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 16px 0px 0px 16px;
    text-align: center;
    transition: all 0.5s;
    padding: 8px 0;
    vertical-align: middle;

    .divider {
      display: inline-block;
      width: 33px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      margin: 4px auto;
    }
  }
}

.sidebar-mobile {
  display: none;
  position: fixed;
  width: 48px;
  height: 48px;
  right: 16px;
  bottom: 36px;
  z-index: 99;
  align-items: center;
  backdrop-filter: blur(40px);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

.sidebar-popup {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 29px;
  margin-bottom: 16px;

  &-item {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      height: 100%;
      right: -1px;
      width: 1px;
      background: rgba(0, 0, 0, 0.15);
    }

    &:last-child::after {
      display: none;
    }

    .tip {
      margin-top: 9px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@include device-max-width-600 {
  .sidebar {
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    transition: all 0.3s;
    visibility: hidden;
    opacity: 0;

    .sidebar-wrap {
      position: absolute;
      bottom: 36px;
      right: 70px;
      min-width: 185px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 16px 100px 0px rgba(0, 0, 0, 0.15);
      padding: 12px;
      gap: 8px;
      border: unset;

      :deep(.sidebar-item) {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        .sidebar-item-inner {
          justify-content: flex-start;
          padding: 0 8px;
          border-radius: 8px;
          width: unset;
          transition: background 0.3s;
          height: unset;
          min-height: 52px;

          .sidebar-item-inner__icon {
            width: 26px;
            height: 26px;
            margin-right: 6px;
            flex-shrink: 0;
            &:hover {
              path {
                fill: #333129;
              }
            }
          }

          .sidebar-item-inner-tooltip {
            height: auto;
            position: unset;
            padding: 0;
            visibility: visible;
            opacity: 1;
            border: unset;
            background: unset;
            backdrop-filter: unset;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            white-space: wrap;
            line-height: 21px;
            text-align: left;
          }

          &:active {
            background: #ffe050;
          }
        }
        .divider {
          display: none;
        }
      }
    }
  }

  .show-popup {
    visibility: visible;
    opacity: 1;
  }

  .hide-popup {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .sidebar-mobile {
    display: flex;
  }
  .back-top {
    display: none;
  }
}
</style>
